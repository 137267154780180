import React from "react"
import styles from '../styles/blurb.module.scss'

const Blurb = ({ title, content }) => (
  <div className={`columns ${styles.container}`}>
    <div className="column is-three-fifths is-offset-one-fifth">
      <h3 className="title is-3">{title}</h3>
      <div className={`is-large content ${styles.content}`} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
)

export default Blurb
