import React from "react"
import { StaticQuery, graphql } from 'gatsby'

import styles from '../styles/facts-list.module.scss'
import FactCard from './fact-card'

const FactsList = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: {sourceInstanceName: { eq: "facts" }}, sort: { order: ASC, fields: childMarkdownRemark___frontmatter___order }) {
          edges {
            node {
              id
              childMarkdownRemark {
                html
                frontmatter {
                  title
                  image {
                    childImageSharp {
                      id
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.container}>
        {data.allFile.edges.map((edge, i) => <FactCard key={i} fact={edge.node}></FactCard>)}
      </div>
    )}
  />
)

export default FactsList