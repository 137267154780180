import React from "react"
import styles from '../styles/footer.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <div>
    <div className="columns">
      <div className="column">
        The End ICBC campaign is a project of the BC Libertarian Party. But no matter what your political views are, we want to work with you to bring choice to auto insurance in BC.
For more on our plan to allow full competition in BC's auto insurance sector, visit <a href="https://libertarian.bc.ca/issue/end_icbc/?utm_source=endicbc">our policy page</a>.
      </div>

      <div className={`column has-text-right ${styles.links}`}>
        <a href="https://libertarian.bc.ca?utm_source=endicbc">
          <FontAwesomeIcon icon={faLink} />
        </a>

        <a href="mailto:info@libertarian.bc.ca">
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
        </a>

        <a href="https://www.facebook.com/BC.Libertarian.Party/">
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>

        <a href="https://www.instagram.com/BClibertarians/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>

        <a href="https://twitter.com/BC_Libertarians">
          <FontAwesomeIcon icon={faTwitterSquare} />
        </a>
      </div>
    </div>
  </div>
)

export default Footer
