import React from "react"
import Img from 'gatsby-image'

import styles from '../styles/fact-card.module.scss'

const FactCard = ({ fact }) => (
  <div className={`columns ${styles.container}`}>
    <div className={`column is-one-third`}>
      <Img fluid={fact.childMarkdownRemark.frontmatter.image.childImageSharp.fluid} />
    </div>

    <div className={`column is-two-thirds`}>
      <h3 className="title is-3">{fact.childMarkdownRemark.frontmatter.title}</h3>
      <div className="subtitle" dangerouslySetInnerHTML={{ __html: fact.childMarkdownRemark.html }} />
    </div>
  </div>
)

export default FactCard
