import React from "react"
import { StaticQuery, graphql } from 'gatsby'

import EventCard from './event-card'

const UpcomingEvent = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(sort: { order: ASC, fields: childMarkdownRemark___frontmatter___date }, filter: {sourceInstanceName: {eq: "events"}}) {
          edges {
            node {
              id
              childMarkdownRemark {
                html
                frontmatter {
                  title
                  date(
                    formatString: "dddd DD MMMM YYYY"
                  )
                  start_time
                  end_time
                  location
                  address
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="upcoming-event">
        <EventCard event={data.allFile.edges[0].node}></EventCard>
      </div>
    )}
  />
)

export default UpcomingEvent