import React from "react"
import styles from '../styles/hero.module.scss'

const Hero = ({ title, subtitle }) => (
  <div className={`${styles.container} hero is-large has-text-centered`}>
    <div className={`${styles.body} hero-body`}>
      <div className="container">
        <h1 className={`${styles.title} title is-1 has-text-dark`}>{title}</h1>
        <h2 className={`${styles.subtitle} subtitle is-2 has-text-dark`}>{subtitle}</h2>
      </div>
    </div>

    <div className={styles.sign}></div>
  </div>
)

export default Hero
