import React from "react"
import { StaticQuery, graphql } from 'gatsby'

import Layout from "../components/layout"
import Section from "../components/section"

import Hero from "../components/hero"
import Blurb from "../components/blurb"

import FactsList from "../components/facts-list"
import ActionsList from "../components/actions-list"
import UpcomingEvent from "../components/upcoming-event"
import SubscribeForm from "../components/subscribe-form"
import Footer from "../components/footer"

import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile (filter: {sourceInstanceName: {eq: "pages"} name: {eq: "landing"}}) {
              edges {
                node {
                  id
                  childMarkdownRemark {
                    html
                    frontmatter {
                      title
                      subtitle
                      heading
                  }
                }
              }
            }
          }
        }`
      }
        render={data => (
          <Layout>
            <SEO title="Home" />

            <Section padded={false}>
              <Hero title={data.allFile.edges[0].node.childMarkdownRemark.frontmatter.title} subtitle={data.allFile.edges[0].node.childMarkdownRemark.frontmatter.subtitle} />
            </Section>

            <Section>
              <Blurb title={data.allFile.edges[0].node.childMarkdownRemark.frontmatter.heading} content={data.allFile.edges[0].node.childMarkdownRemark.html} />
            </Section>

            <Section>
              <SubscribeForm />
            </Section>

            <Section>
              <FactsList />
            </Section>

            <Section>
              <UpcomingEvent />
            </Section>

            <Section>
              <ActionsList />
            </Section>

            <Section>
              <Footer />
            </Section>
          </Layout>
        )}
      />
    )
  }
}

export default IndexPage