import React from "react"
import { StaticQuery, graphql } from 'gatsby'

import styles from '../styles/actions-list.module.scss'
import ActionCard from './action-card'

const ActionsList = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: {sourceInstanceName: {eq: "actions"}}) {
          edges {
            node {
              id
              childMarkdownRemark {
                html
                frontmatter {
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={`columns ${styles.container}`}>
        {data.allFile.edges.map((edge, i) => <ActionCard key={i} action={edge.node} className="column"></ActionCard>)}
      </div>
    )}
  />
)

export default ActionsList