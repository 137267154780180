import React from "react"

const ActionCard = ({ className, action }) => (
  <div className={`card is-flex ${className}`}>
    <div className="card-content">
      <h3 className="title is-3">{action.childMarkdownRemark.frontmatter.title}</h3>
      <div className="content">
        <div className="subtitle" dangerouslySetInnerHTML={{ __html: action.childMarkdownRemark.html }} />
      </div>
    </div>
  </div>
)

export default ActionCard
